<template>
  <div>
        <nav class="navbar navbar-expand-md navbar-light navbar-laravel">
            <div class="container size-mobile d-flex justify-content-between" style="position:relative">
               
                <div class="d-flex flex-row align-items-center"> 
                        <a href="#" @click="$router.go(-1)" class="ml-3 mr-3 link-back">
                            <img src="../assets/img/back-black.png" alt="Ibunda" class="icon-logo">
                        </a> 
                    <span style="font-family: Montserrat;font-style: normal;font-weight: 600;font-size: 16px;color: #181817;">{{$route.name}}
                    </span>
                </div> 
            </div>
        </nav>
        <main class="main-intro">
            <router-view></router-view>
        </main>
    </div>
</template>

<script>
export default {
  name: "MasterNavbar",
  methods:{
      logout(){
          localStorage.clear();
          this.$router.replace('/login')
      },
  }
};
</script>

<style>
.navbar{
    position: absolute;
}
</style>